exports.components = {
  "component---src-pages-302-fountain-of-youth-js": () => import("./../../../src/pages/302-fountain-of-youth.js" /* webpackChunkName: "component---src-pages-302-fountain-of-youth-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-acid-free-chemical-peels-ottawa-js": () => import("./../../../src/pages/acid-free-chemical-peels-ottawa.js" /* webpackChunkName: "component---src-pages-acid-free-chemical-peels-ottawa-js" */),
  "component---src-pages-acid-free-vitamin-a-skin-peel-js": () => import("./../../../src/pages/acid-free-vitamin-a-skin-peel.js" /* webpackChunkName: "component---src-pages-acid-free-vitamin-a-skin-peel-js" */),
  "component---src-pages-acne-scars-treatment-ottawa-js": () => import("./../../../src/pages/acne-scars-treatment-ottawa.js" /* webpackChunkName: "component---src-pages-acne-scars-treatment-ottawa-js" */),
  "component---src-pages-acne-treatment-ottawa-js": () => import("./../../../src/pages/acne-treatment-ottawa.js" /* webpackChunkName: "component---src-pages-acne-treatment-ottawa-js" */),
  "component---src-pages-afterglow-chemical-peel-ottawa-js": () => import("./../../../src/pages/afterglow-chemical-peel-ottawa.js" /* webpackChunkName: "component---src-pages-afterglow-chemical-peel-ottawa-js" */),
  "component---src-pages-aging-and-dehydrated-skin-js": () => import("./../../../src/pages/aging-and-dehydrated-skin.js" /* webpackChunkName: "component---src-pages-aging-and-dehydrated-skin-js" */),
  "component---src-pages-anteage-stem-cell-facial-js": () => import("./../../../src/pages/anteage-stem-cell-facial.js" /* webpackChunkName: "component---src-pages-anteage-stem-cell-facial-js" */),
  "component---src-pages-back-acne-treatment-ottawa-js": () => import("./../../../src/pages/back-acne-treatment-ottawa.js" /* webpackChunkName: "component---src-pages-back-acne-treatment-ottawa-js" */),
  "component---src-pages-bio-microneedling-js": () => import("./../../../src/pages/bio-microneedling.js" /* webpackChunkName: "component---src-pages-bio-microneedling-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-blog-slug-js": () => import("./../../../src/pages/{Blog.slug}.js" /* webpackChunkName: "component---src-pages-blog-slug-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-cystic-acne-treatment-ottawa-js": () => import("./../../../src/pages/cystic-acne-treatment-ottawa.js" /* webpackChunkName: "component---src-pages-cystic-acne-treatment-ottawa-js" */),
  "component---src-pages-dmk-enzyme-therapy-js": () => import("./../../../src/pages/dmk-enzyme-therapy.js" /* webpackChunkName: "component---src-pages-dmk-enzyme-therapy-js" */),
  "component---src-pages-facial-treatments-ottawa-js": () => import("./../../../src/pages/facial-treatments-ottawa.js" /* webpackChunkName: "component---src-pages-facial-treatments-ottawa-js" */),
  "component---src-pages-herbal-green-peel-js": () => import("./../../../src/pages/herbal-green-peel.js" /* webpackChunkName: "component---src-pages-herbal-green-peel-js" */),
  "component---src-pages-holistic-acne-clinic-ottawa-js": () => import("./../../../src/pages/holistic-acne-clinic-ottawa.js" /* webpackChunkName: "component---src-pages-holistic-acne-clinic-ottawa-js" */),
  "component---src-pages-hyperpigmentation-melasma-treatment-ottawa-js": () => import("./../../../src/pages/hyperpigmentation-melasma-treatment-ottawa.js" /* webpackChunkName: "component---src-pages-hyperpigmentation-melasma-treatment-ottawa-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-led-photo-facial-light-therapy-js": () => import("./../../../src/pages/led-photo-facial-light-therapy.js" /* webpackChunkName: "component---src-pages-led-photo-facial-light-therapy-js" */),
  "component---src-pages-microneedling-ottawa-js": () => import("./../../../src/pages/microneedling-ottawa.js" /* webpackChunkName: "component---src-pages-microneedling-ottawa-js" */),
  "component---src-pages-mineral-makeup-js": () => import("./../../../src/pages/mineral-makeup.js" /* webpackChunkName: "component---src-pages-mineral-makeup-js" */),
  "component---src-pages-new-client-acne-consultation-js": () => import("./../../../src/pages/new-client-acne-consultation.js" /* webpackChunkName: "component---src-pages-new-client-acne-consultation-js" */),
  "component---src-pages-non-invasive-ultrasonic-facial-js": () => import("./../../../src/pages/non-invasive-ultrasonic-facial.js" /* webpackChunkName: "component---src-pages-non-invasive-ultrasonic-facial-js" */),
  "component---src-pages-osmosismd-js": () => import("./../../../src/pages/osmosismd.js" /* webpackChunkName: "component---src-pages-osmosismd-js" */),
  "component---src-pages-practice-policies-form-js": () => import("./../../../src/pages/practice-policies-form.js" /* webpackChunkName: "component---src-pages-practice-policies-form-js" */),
  "component---src-pages-products-js": () => import("./../../../src/pages/products.js" /* webpackChunkName: "component---src-pages-products-js" */),
  "component---src-pages-radiance-hydro-infusion-facial-js": () => import("./../../../src/pages/radiance-hydro-infusion-facial.js" /* webpackChunkName: "component---src-pages-radiance-hydro-infusion-facial-js" */),
  "component---src-pages-restorative-bio-peel-js": () => import("./../../../src/pages/restorative-bio-peel.js" /* webpackChunkName: "component---src-pages-restorative-bio-peel-js" */),
  "component---src-pages-rosacea-treatment-sensitive-skin-js": () => import("./../../../src/pages/rosacea-treatment-sensitive-skin.js" /* webpackChunkName: "component---src-pages-rosacea-treatment-sensitive-skin-js" */),
  "component---src-pages-skin-preparations-for-weddings-js": () => import("./../../../src/pages/skin-preparations-for-weddings.js" /* webpackChunkName: "component---src-pages-skin-preparations-for-weddings-js" */),
  "component---src-pages-skin-tags-red-dots-milia-removal-ottawa-js": () => import("./../../../src/pages/skin-tags-red-dots-milia-removal-ottawa.js" /* webpackChunkName: "component---src-pages-skin-tags-red-dots-milia-removal-ottawa-js" */),
  "component---src-pages-testimonials-js": () => import("./../../../src/pages/testimonials.js" /* webpackChunkName: "component---src-pages-testimonials-js" */),
  "component---src-pages-treatments-js": () => import("./../../../src/pages/treatments.js" /* webpackChunkName: "component---src-pages-treatments-js" */),
  "component---src-pages-virtual-acne-program-js": () => import("./../../../src/pages/virtual-acne-program.js" /* webpackChunkName: "component---src-pages-virtual-acne-program-js" */)
}

